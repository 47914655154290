import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AcademicService {
  basicAcademicUrl = '/api/user_management/basic-academic-qualification/';
  basicAcademicByUserUrl =
    '/api/user_management/basic-academic-qualification-by-user/';
  professionalAcademicByUserUrl =
    '/api/user_management/professional-qualification-by-user-id/';
  basicAcademicTypeUrl =
    '/api/user_management/basic-academic-qualification-type/';
  basicAcademicTypeCheckUrl =
    '/api/user_management/basic-academic-qualification-type-check/';
  professionalAcademicUrl =
    '/api/user_management/professional-academic-qualification/';
  qualificationLevelUrl = 'api/user_management/qualification-level-search/';
  professionalQualificationUrl =
    'api/user_management/professional-academic-qualification/';
  basicAcademicQualificationVerificationUrl =
    'api/user_management/basic-academic-qualification-verification/';
  professionalQualificationCheckUrl =
    'api/user_management/professional-qualification-check/';
  userLevelEducationUrl = 'api/registration/user-education-level/';

  constructor(private http: HttpClient) {}

  loadUserBasicQualification(): Observable<any> {
    return this.http.get(this.basicAcademicUrl).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveBasicAcademicQualification(payload): Observable<any> {
    return this.http.post(this.basicAcademicUrl, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  editBasicQualification(payload, id): Observable<any> {
    return this.http.patch<any>(this.basicAcademicUrl + id + '/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  loadAllBasicAcademicTypes(): Observable<any> {
    return this.http.get(this.basicAcademicTypeUrl).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  basicQualificationCheck(id): Observable<any> {
    return this.http.get(this.basicAcademicTypeCheckUrl + '?search=' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  loadBasicEducationQualificationByUser(user): Observable<any> {
    return this.http.get(this.basicAcademicByUserUrl + '?user=' + user).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  loadProfessionalQualificationByUser(user): Observable<any> {
    return this.http
      .get(this.professionalAcademicByUserUrl + '?user=' + user)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  professionalQualificationCheck(id): Observable<any> {
    return this.http
      .get(this.professionalQualificationCheckUrl + '?id=' + id)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  loadUserProfessionalQualification(): Observable<any> {
    return this.http.get(this.professionalAcademicUrl).pipe(
      map((response) => {
        return response;
      })
    );
  }

  qualificationLevelSearch(id): Observable<any> {
    return this.http
      .get<any>(this.qualificationLevelUrl + '?search=' + id)
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
        })
      );
  }

  saveProfessionalQualification(payload) {
    return this.http.post(this.professionalQualificationUrl, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateBasicQualification(payload, id): Observable<any> {
    return this.http
      .patch<any>(this.professionalQualificationUrl + id + '/', payload)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  loadVerification(user) {
    return this.http
      .get(this.basicAcademicQualificationVerificationUrl + '?user=' + user)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  loadUserEducationLevel(level) {
    return this.http.get(this.userLevelEducationUrl + '?level=' + level).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
