import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageKey } from '../models/storage.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  headers = new HttpHeaders({
    Authorization: `Token ${this.storage.read(StorageKey.AUTH_TOKEN)}`,
  });

  constructor(
    private http: HttpClient,
    private newHttp: HttpClient,
    private backend: HttpBackend,
    private storage: StorageService
  ) {
    this.newHttp = new HttpClient(this.backend);
  }

  getFacilities(): Observable<any> {
    return this.http.get<any>(`api/system-administration/facility/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getDocumentationTypes(): Observable<any> {
    return this.http
      .get<any>(`api/system-administration/documentation-type/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDocumentation(): Observable<any> {
    return this.http
      .get<any>(`api/system-administration/admin-get-documentation/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editDocumentation(id, payload): Observable<any> {
    return this.http
      .patch<any>(`api/system-administration/documentation/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveDocumentation(payload): Observable<any> {
    return this.newHttp
      .post<any>(`api/system-administration/documentation/`, payload, {
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // saveFacilities (payload): Observable<any> {
  //   return this.__http
  //     .post<any> (`api/system-administration/facility/`, payload)
  //     .pipe (
  //       map (response => {
  //         return response;
  //       }),
  //     );
  // }

  editFacilities(id, payload): Observable<any> {
    return this.http
      .patch<any>(`api/system-administration/facility/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getRoleByID(id) {
    return this.http
      .get<any>(`api/system-administration/admin-menu-roles/${id}/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveNewRole(payload) {
    return this.http
      .post<any>(`api/system-administration/post-user-menu/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateUserRole(id, payload) {
    return this.http
      .patch<any>(`api/system-administration/post-user-menu/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  patchAdminRoles(id, payload) {
    return this.http
      .patch<any>(
        `api/system-administration/save-admin-menu-roles/${id}/`,
        payload
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDataFinancialYears() {
    return this.http
      .get<any>(`api/system-administration/data-financial-year/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }


  getUserByEmail(email: string) {
    return this.http
      .get<any>(`api/user_management/search_user_by_email/` + '?email=' + email)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteUserByEmail(payload) {
    return this.http
      .post<any>(`api/user_management/user-cleaner/`, payload)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

}
