import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageKey } from '../models/storage.model';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  attachmentTypeUrl = 'api/user_management/attachment-type/';
  attachmentCPDTypeUrl = 'api/user_management/attachment-cpd-type/';
  attachmentCPDPractitionersTypeUrl = 'api/user_management/attachment-cpd-type-practitioners/';
  attachmentUrl = 'api/user_management/attachment/';
  attachmentSearchUrl = 'api/user_management/attachment-search/';
  httpOptions: any;
  headers = new HttpHeaders({
    Authorization: 'Token ' + this.storage.read(StorageKey.AUTH_TOKEN),
  });

  constructor(
    private http: HttpClient,
    private newHttp: HttpClient,
    private backend: HttpBackend,
    private storage: StorageService
  ) {
    this.newHttp = new HttpClient(this.backend);
  }

  loadAttachmentType(): Observable<any> {
    return this.http.get(this.attachmentTypeUrl).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  loadAttachmentCPDType(): Observable<any> {
    return this.http.get(this.attachmentCPDTypeUrl).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  loadAttachmentCPDTypeForPractitioner(): Observable<any> {
    return this.http.get(this.attachmentCPDPractitionersTypeUrl).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  saveUserAttachment(payload): Observable<any> {
    return this.newHttp
      .post(this.attachmentUrl, payload, { headers: this.headers })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  loadUserAttachments(): Observable<any> {
    return this.http.get(this.attachmentUrl).pipe(
      map((response: any) => {
        if (response.length) {
          return response;
        }
      })
    );
  }

  updateAttachment(payload, id): Observable<any> {
    return this.newHttp
      .patch(this.attachmentUrl + id + '/', payload, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteAttachment(payload, id): Observable<any> {
    return this.newHttp
      .patch(this.attachmentUrl + id + '/', payload, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  checkAttachmentTypeAvailability(value): Observable<any> {
    return this.http
      .get<any>(this.attachmentSearchUrl + '?search=' + value)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
