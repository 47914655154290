import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {StorageKey} from "../models/storage.model";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ExaminationService {

  headers = new HttpHeaders({
    Authorization: 'Token ' + this.storage.read(StorageKey.AUTH_TOKEN),
  });

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private newHttp: HttpClient,
    private backend: HttpBackend
  ) {
    this.newHttp = new HttpClient(this.backend);
  }

  saveExaminationSit(payload): Observable<any> {
    return this.http.post<any>('api/examination/examination-sit/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  relocateExamination(payload): Observable<any> {
    return this.http.post<any>('api/examination/examination-relocate-application/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  loadGeneralExaminationSit(): Observable<any> {
    return this.http.get<any>('api/examination/general-examination-sit/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateExaminationSit(payload): Observable<any> {
    return this.http.post<any>('api/examination/examination-sit-update/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getExaminationSit(): Observable<any> {
    return this.http.get<any>('api/examination/examination-sit/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getAllExaminations(): Observable<any> {
    return this.http.get<any>('api/examination/examinations/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getAllExaminationCategories(): Observable<any> {
    return this.http.get<any>('api/examination/examinations-category/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  applyExaminationApplication(payload): Observable<any> {
    return this.http.post<any>('api/examination/examination-application/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  loadActiveExaminationWindow(): Observable<any> {
    return this.http.get<any>('api/examination/load-active-examination-window/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getExaminationApplication(): Observable<any> {
    return this.http.get<any>('api/examination/examination-application/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCompleteExaminationApplication(): Observable<any> {
    return this.http.get<any>('api/examination/complete-examination-applications/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPractitionerCompleteExaminationApplication(): Observable<any> {
    return this.http.get<any>('api/examination/practitioner-complete-examination-applications/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getExaminationVenue(): Observable<any> {
    return this.http.get<any>('api/examination/examination-venues/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getExaminationSitVenue(): Observable<any> {
    return this.http.get<any>('api/examination/examination-sit-venues/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveExaminationSitVenue(payload): Observable<any> {
    return this.http.post<any>('api/examination/examination-sit-venues/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPendingPaymentApplications(): Observable<any> {
    return this.http.get<any>('api/examination/pending-payment-examination-applications/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPaidPaymentApplications(): Observable<any> {
    return this.http.get<any>('api/examination/paid-payment-examination-applications/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getExportPaidPaymentApplications(payload): Observable<any> {
    return this.http.post<any>('api/examination/export-paid-examination-applications/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateExaminationApplication(id, payload): Observable<any> {
    return this.http.patch<any>('api/examination/update-examination-application/' + id + '/',  payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }


  uploadExamsResults(payload) {
    return this.newHttp
      .post('api/examination/upload-exams-results/', payload, { headers: this.headers })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}
