import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserAddressService {
  constructor(private http: HttpClient) {}

  getLocations(): Observable<any> {
    return this.http.get(`api/user_management/location/`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  getUserAddresses(): Observable<any> {
    return this.http.get(`api/user_management/user-address/`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  deleteUserAddresses(id, payload): Observable<any> {
    return this.http
      .patch(`api/user_management/user-address/${id}/`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  saveUserLocation(payload): Observable<any> {
    return this.http.post(`api/user_management/user-address/`, payload).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  getUserAddressesByUserId(userID): Observable<any> {
    return this.http
      .get(`api/user_management/user-address/?user_id=${userID}`)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }
}
