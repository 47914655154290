import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CouncilService {
  constructor(private http: HttpClient) {}

  saveUserProfession(payload): Observable<any> {
    return this.http
      .post<any>(`api/user_management/post-user-profession/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getProfessions(): Observable<any> {
    return this.http.get<any>(`api/registration/profession/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCouncilProfessions(id): Observable<any> {
    return this.http
      .get<any>(`api/registration/profession2/?search=${id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveCouncilProfession(payload): Observable<any> {
    return this.http.post<any>(`api/registration/profession2/`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateCouncilProfession(id, payload): Observable<any> {
    return this.http
      .patch<any>(`api/registration/profession/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteUserProfession(id, payload): Observable<any> {
    return this.http
      .patch<any>(`api/user_management/user-profession/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateUserProfession(id, payload): Observable<any> {
    return this.http
      .patch<any>(`api/user_management/user-profession/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCouncils(): Observable<any> {
    return this.http.get<any>(`api/user_management/council/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveCouncil(payload): Observable<any> {
    return this.http.post<any>(`api/user_management/council/`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCouncil(id): Observable<any> {
    return this.http.get<any>(`api/user_management/council/${id}/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateCouncil(id, payload): Observable<any> {
    return this.http
      .patch<any>(`api/user_management/council/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getUserProfession(): Observable<any> {
    return this.http.get<any>(`api/user_management/user-profession/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCouncilPrograms(councilID): Observable<any> {
    return this.http
      .get<any>(`api/user_management/all-programs/?council=${councilID}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveCouncilProgram(payload): Observable<any> {
    return this.http
      .post<any>(`api/user_management/all-programs/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateCouncilProgram(programID, councilID, payload): Observable<any> {
    return this.http
      .patch<any>(
        `api/user_management/all-programs/${programID}/?council=${councilID}`,
        payload
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
