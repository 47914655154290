import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FormGroup,
  Validators,
  FormControl,
  ValidatorFn,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class WorkExperienceService {
  // private countryURL = './src/assets/files/CountryCodes.json';

  form = new FormGroup(
    {
      id: new FormControl('', []),
      employer_name: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      employer_category: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      employement_type: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      work_country: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      region_state: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      council_province_suburb: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      diseases_treated: new FormControl('', []),
      job_title: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(350),
      ]),
      duties: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(990),
      ]),
      start_year: new FormControl('', [
        Validators.required,
        Validators.maxLength(350),
      ]),
      end_year: new FormControl('', [
        Validators.required,
        Validators.maxLength(350),
      ]),
      is_current: new FormControl('no', Validators.required),
      has_worked_at_facility: new FormControl('no', Validators.required),
    },
    { validators: [this.dateLessThan()] }
  );

  constructor(private http: HttpClient) {}

  dateLessThan(): ValidatorFn {
    return (group: FormGroup) => {
      const f = group.get('start_year');
      const t = group.get('end_year');
      if (f.value > t.value) {
        return { dates: true };
      }
      return null;
    };
  }

  populateForm(data: any): void {
    this.form.patchValue(data);
  }

  addWorkExperienceData(workExperienceObj: any): Observable<any> {
    return this.http.post(
      '/api/user_management/work-experience/',
      workExperienceObj
    );
  }

  getWorkExperience(): Observable<any> {
    return this.http.get('api/user_management/work-experience/').pipe(
      map((workExperiences) => {
        if (workExperiences) {
          return workExperiences;
        }
      })
    );
  }

  getAllWorkDetailExperience(): Observable<any> {
    return this.http.get('api/user_management/work-experience-list/').pipe(
      map((workExperiences) => {
        if (workExperiences) {
          return workExperiences;
        }
      })
    );
  }

  getAllWorkDetailExperienceByID(id): Observable<any> {
    return this.http
      .get(`api/user_management/work-experience-list/${id}/`)
      .pipe(
        map((workExperiences) => {
          if (workExperiences) {
            return workExperiences;
          }
        })
      );
  }

  editWorkExperience(id: string, payload: any): Observable<any> {
    return this.http
      .patch<any>(`api/user_management/work-experience/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteWorkExperience(id: string, payload: any): Observable<any> {
    return this.http
      .patch<any>(`api/user_management/work-experience/${id}/`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAllOperatingFacilities(): Observable<any> {
    return this.http.get('api/system-administration/operating-facility/').pipe(
      map((facilities) => {
        if (facilities) {
          return facilities;
        }
      })
    );
  }
}
