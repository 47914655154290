import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CustomResponse} from '../models/custom-response.model';
import {InitialState} from '../models/state.model';

@Injectable({
  providedIn: 'root'
})
export class InternshipService {

  internshipAllAppliedInternshipURL =
    'api/internship/all-applied-internship/';
  internshipAllApprovedInternshipURL =
    'api/internship/all-approved-internship/';

  initialState = InitialState;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private httpNoAuth: HttpClient,
  ) {
    this.httpNoAuth = new HttpClient(this.httpBackend);
  }

  saveInternshipConfig(payload): Observable<any> {
    return this.http.post<any>('api/internship/internship-config/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateInternshipConfig(payload): Observable<any> {
    let id = payload['id']
    return this.http.patch<any>('api/internship/internship-config/' + id + '/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipConfig(): Observable<any> {
    return this.http.get<any>('api/internship/internship-config/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipSiteType(): Observable<any> {
    return this.http.get<any>('api/internship/internship-site-type/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveInternshipSite(payload): Observable<any> {
    return this.http.post<any>('api/internship/internship-site/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateInternshipSite(payload): Observable<any> {
    let id = payload['id']
    return this.http.patch<any>('api/internship/internship-site-registrar/' + id + '/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveInternshipApplication(payload): Observable<any> {
    return this.http.post<any>('api/internship/internship-application/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveInternshipApplicationUpdate(payload): Observable<any> {
    return this.http.post<any>('api/internship/internship-application-update/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipSites(): Observable<any> {
    return this.http.get<any>('api/internship/internship-site/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipSitesRegistrar(page: number, pageSize: number, searchQueryTerm?: string): Observable<any> {
    let uri;
    const URL = 'api/internship/internship-site-registrar/';
    if (searchQueryTerm?.length > 2 && searchQueryTerm !== '') {
      uri = `${URL}?page=${page}&page_size=${pageSize}&search=${searchQueryTerm}`;
    } else {
      uri = `${URL}?page=${page}&page_size=${pageSize}`;
    }
    return this.http.get<any>(uri)
      .pipe(
        map((response: CustomResponse) => {
          this.initialState = {
            page: response.page_number,
            pageSize: response.per_page,
            totalRecords: response.total_count
          }
          return response;
        })
      );
  }

  getPractitionersApplications(): Observable<any> {
    return this.http.get<any>('api/internship/practitioner-internship-application/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getRegionLocations(): Observable<any> {
    return this.http.get<any>('api/internship/internship-training-institution-regions/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipHealthFacility(): Observable<any> {
    return this.http.get<any>('api/internship/internship-health-facility/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }


  getAllAppliedInternship(page: number, pageSize: number, searchQueryTerm?: string): Observable<any> {
    let uri;
    if (searchQueryTerm?.length > 2 && searchQueryTerm !== '') {
      uri = `${this.internshipAllAppliedInternshipURL}?page=${page}&page_size=${pageSize}&search=${searchQueryTerm}`;
    } else {
      uri = `${this.internshipAllAppliedInternshipURL}?page=${page}&page_size=${pageSize}`;
    }
    return this.http.get<any>(uri)
      .pipe(
        map((response: CustomResponse) => {
          this.initialState = {
            page: response.page_number,
            pageSize: response.per_page,
            totalRecords: response.total_count
          };
          return response;
        })
      );
  }

  updateInternshipApplication(payload): Observable<any> {
    return this.http.post<any>('api/internship/all-applied-internship/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getAllApprovedInternship(page: number, pageSize: number, searchQueryTerm?: string): Observable<any> {
    let uri;
    if (searchQueryTerm?.length > 2 && searchQueryTerm !== '') {
      uri = `${this.internshipAllApprovedInternshipURL}?page=${page}&page_size=${pageSize}&search=${searchQueryTerm}`;
    } else {
      uri = `${this.internshipAllApprovedInternshipURL}?page=${page}&page_size=${pageSize}`;
    }
    return this.http.get<any>(uri)
      .pipe(
        map((response: CustomResponse) => {
          this.initialState = {
            page: response.page_number,
            pageSize: response.per_page,
            totalRecords: response.total_count
          };
          return response;
        })
      );
  }

  updateApproveApplication(payload): Observable<any> {
    return this.http.post<any>('api/internship/all-approved-internship/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPractitionerInternshipApplicationDetails(): Observable<any> {
    return this.http.get<any>('api/internship/practitioner-internship-application-tracker-details/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getAllBanks(): Observable<any> {
    return this.http.get<any>('api/internship/all-banks/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveInternBankDetails(payload): Observable<any> {
    return this.http.post<any>('api/internship/save-practitioner-bank-details/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateInternBankDetails(payload, id): Observable<any> {
    return this.http.patch<any>('api/internship/save-practitioner-bank-details/' + id + '/', payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPractitionerBankDetails(): Observable<any> {
    return this.http.get<any>('api/internship/internship-bank-details/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipPreConditions(): Observable<any> {
    return this.http.get<any>('api/internship/internship-pre-condition/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  generateInternshipCertificate(payload): Observable<any> {
    return this.httpNoAuth.post('api/internship/generate-internship-certificate/', payload, {responseType: 'blob'}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getInternshipSitesByIntake(id): Observable<any> {
    return this.http.get<any>('api/internship/internship-site-by-intake/' + '?intake=' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }


}
