import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageKey } from '../models/storage.model';
import { StorageService } from './storage.service';

const { NON_LAB_TESTER } = StorageKey;

@Injectable({
  providedIn: 'root',
})
export class NonLabTesterService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  getPersonalNonLabTester(): Observable<any> {
    return this.http.get(`api/application/personal-non-lab-tester/`).pipe(
      map((response) => {
        if (response) {
          if (response[0] !== undefined) {
            this.storage.save(NON_LAB_TESTER, true);
          }
          return response;
        }
      })
    );
  }

  savePersonalNonLabTester(payload): Observable<any> {
    return this.http
      .post(`api/application/personal-non-lab-tester/`, payload)
      .pipe(
        map((response) => {
          if (response) {
            this.storage.save(NON_LAB_TESTER, true);
            return response;
          }
        })
      );
  }

  loadUserRoles(): Observable<any> {
    return this.http.get(`api/system-administration/get-menu-role/`).pipe(
      map((response) => {
        if (response) {
          return response;
        }
      })
    );
  }

  updateRoleToNonLabTester(payload, id): Observable<any> {
    return this.http
      .patch(`api/system-administration/post-user-menu/${id}/`, payload)
      .pipe(
        map((response) => {
          if (response) {
            this.storage.save(NON_LAB_TESTER, true);
            return response;
          }
        })
      );
  }

  getNonLabTestHIVExaminationApplication(): Observable<any> {
    return this.http
      .get(`api/application/get-non-lab-tester-hiv-examination-application/`)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  updateNonLabTestHIVExaminationApplication(id, payload): Observable<any> {
    return this.http
      .patch(
        `api/application/non-lab-tester-hiv-examination-application/${id}/`,
        payload
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  adminUpdateNonLabTestHIVExaminationApplication(id, payload): Observable<any> {
    return this.http
      .patch(
        `api/application/admin-save-non-lab-tester-hiv-examination-application/${id}/`,
        payload
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  saveNonLabTestHIVExaminationApplication(payload): Observable<any> {
    return this.http
      .post(
        `api/application/non-lab-tester-hiv-examination-application/`,
        payload
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  saveNonLabTestHIVLicencingApplication(payload): Observable<any> {
    return this.http
      .post(
        `api/application/non-lab-tester-hiv-licencing-application/`,
        payload
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  updateNonLabTestHIVLicencingApplication(id, payload): Observable<any> {
    return this.http
      .patch(
        `api/application/non-lab-tester-hiv-licencing-application/${id}/`,
        payload
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  getNonLabTestHIVLicencingApplication(): Observable<any> {
    return this.http
      .get(`api/application/get-non-lab-tester-hiv-licencing-application/`)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  getAdminHIVNonLabTesterExamination(): Observable<any> {
    return this.http
      .get(
        `api/application/get-admin-non-lab-tester-hiv-examination-applications/`
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }

  getFilterAdminHIVNonLabTesterExamination(
    startDate,
    endDate,
    applicationStatus
  ): Observable<any> {
    return this.http
      .get(
        `api/application/filter-get-admin-non-lab-tester-hiv-examination-applications/?startDate=${startDate}&endDate=${endDate}&applicationStatus=${applicationStatus}`
      )
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
        })
      );
  }
}
