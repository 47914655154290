interface KeyValuePair {
  name: string;
  value: string;
}

export function dateRangeUtil() {
  const dateRange: any[] = [];
  const currentYear = new Date().getFullYear();

  for (let i = 1960; i <= parseInt(currentYear.toString()); i++) {
    const d: KeyValuePair = { name: i.toString(), value: i.toString() };
    dateRange.push(d);
  }

  return dateRange;
}


export function dateRangeUtilFromCurrent() {
  const dateRange: any[] = [];
  const currentYear = new Date().getFullYear();

  for (let i = 2022; i <= parseInt(currentYear.toString()); i++) {
    const d: KeyValuePair = { name: i.toString(), value: i.toString() };
    dateRange.push(d);
  }

  return dateRange;
}
